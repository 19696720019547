import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
// import { jobs } from "../../../common/data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getCategorylist as onGetCategoryList,
} from "store/actions";

// import { ImageCell } from "./packageListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

// import { deleteCategory } from "../../../helpers/smm_helper";
import AdminContext from '../../../context/adminContext';
import axios from 'axios';

function OrderManagement() {
    const context = useContext(AdminContext);
    const { orders, GetAllOrders } = context;

    useEffect(() => {
        GetAllOrders();
    }, []);

    // port
    const host = process.env.REACT_APP_HOST
    // const host = "http://localhost:8001/api/v2"
    //meta title
    document.title = "Order Management| ";

    const [modal, setModal] = useState(false);
    // const [isEdit, setIsEdit] = useState(null);
    // const [editID, setEditId] = useState('');
    // const [categoryData, setCategoryData] = useState([])
    // const [editCategory, setEditcategory] = useState(null);

    const [state, setState] = useState({
        editId: null,
        isLoading: false,
        delete_id: null,
        edit: null,
        status: '',
    })
    const {
        isLoading,
        delete_id,
    } = state

    const updateState = data => setState(state => ({ ...state, ...data }))

    const toggle = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    };

    const handleEditClick = arg => {
        // console.log(arg, 'arg')
        toggle();
        setIsEdit(true)
        // const data = arg;
        setFormState({
            editId: arg._id,
            // category: arg.category,   
            status: arg.status,
        })
        updateState({
            editId: arg._id,
            // category: arg.category,   
            status: arg.status,
        })
        // setIsEdit(true);
        toggle();
    };

    //delete 
    // const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (data) => {
        console.log("onClickDelete", data)
        // setEditcategory(data);
        // setDeleteModal(true);
    };

    // Handle deleting a category
    const handleDeleteService = async () => {
        try {
            const response = await axios.delete(`${host}/package/delete-package/${delete_id}`);
            if (response.data.success == true) {
                // dispatch(onGetCategoryList());
                toast("Successfully Deleted package!", {
                    type: "success",
                });
                // Fetch services after deleting
                GetAllPackages();
                setDeleteModal(false);
            } else {
                toast("Failed to Deleted package", {
                    type: "error",
                });
            }
        } catch (error) {
            console.error('Error Deleting package:', error.message);
            toast("Error Deleting package:", {
                type: "error",
            });
        }
    };
    const columns = useMemo(
        () => [
            {
                Header: 'No',
                accessor: (row, index) => index + 1, // Auto-incremented number
                Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
                filterable: false,
            },
            // {
            //     Header: 'Order Id',
            //     accessor: '_id',
            //     filterable: true,
            // },
            {
                Header: 'User Id',
                accessor: 'userId',
                filterable: true,
            },
            {
                Header: 'Category',
                accessor: 'category_name',
                filterable: true,
            },
            {
                Header: 'Service',
                accessor: 'service_name',
                filterable: true,
            },
            {
                Header: 'Package',
                accessor: 'package_name',
                filterable: true,
                // Cell: ({ value }) => {
                //     //  `value` is the Service ID
                //     const filteredService = services.find((service) => service._id === value);
                //     return filteredService ? filteredService.serviceName : 'loading..';
                // }
            },
            {
                Header: 'Order Plan',
                accessor: 'orderPlan',
                filterable: true,
                Cell: ({ value }) => {
                    const data = value ? value : "Not Selected"
                    return <div className="text-center">{data}</div>;
                }
            },
            {
                Header: 'Link',
                accessor: 'social_link',
                filterable: true,
            },
            {
                Header: 'Quantity',
                accessor: 'quantity',
                filterable: true,
            },
            {
                Header: 'Total Charge',
                accessor: 'totalCharge',
                filterable: true,
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterable: true,
            },
            {
                Header: 'createdAt',
                accessor: 'createdAt',
                Cell: ({ value }) => {
                    const localDate = new Date(value).toLocaleString();
                    return localDate;
                }
            },
            {
                Header: 'updatedAt',
                accessor: 'updatedAt',
                disableFilters: true,
                Cell: ({ value }) => {
                    const localDate = new Date(value).toLocaleString();
                    return localDate;
                }
            },
            {
                Header: 'Action',
                accessor: '_id',
                disableFilters: true,
                Cell: (cellProps) => {
                    const data = cellProps?.data?.find(elem => elem._id === cellProps.value)
                    // console.log(data, 'data')
                    // console.log(data._id)
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link to="#" className="btn btn-sm btn-soft-primary">
                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                ViewUncontrolledTooltip
                            </UncontrolledTooltip> */}
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    // onClick={() => {
                                    //     // const categoryData = cellProps.row.original;
                                    //     // handleEditClick(categoryData);
                                    // }}
                                    onClick={() => {
                                        setIsEdit(true)
                                        // toggle();
                                        setModal(true)
                                        handleEditClick(data);
                                        setFormState({
                                            editId: data._id,
                                            status: data.status,
                                        })
                                        setEditId(data._id)
                                        // updateState({
                                        //     isEdit: true,
                                        //     // edit: data,
                                        //     service: data.service, packageName: data.packageName,
                                        // })
                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );

    const [formState, setFormState] = useState({
        editId: '',
        status: '',
    });

    const [validationErrors, setValidationErrors] = useState({});

    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState(null);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('status', formState.status);
            const url = isEdit
                ? `${host}/orders/update-order-status/${editId}`
                : `${host}/package/create-package`;

            const response = await axios({
                method: isEdit ? 'put' : 'post',
                url,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.data.success === true) {
                // Reset form and perform other actions
                console.log(isEdit ? 'Updated package!' : 'Added package!');
                GetAllOrders();
                toast(`Successfully ${isEdit ? 'updated' : 'added'} Package!`, {
                    type: 'success',
                });
                toggle();
                setFormState({
                    status: '',
                });
                setIsEdit(false);
                setEditId(null);
            } else {
                toast(`Failed to ${isEdit ? 'update' : 'add'} Package`, {
                    type: 'error',
                });
            }
        } catch (error) {
            console.error(`Error ${isEdit ? 'updating' : 'creating'} Package:`, error.message);
            toast(`Error ${isEdit ? 'updating' : 'creating'} Package`, {
                type: 'error',
            });
        }
    };

    return (
        <React.Fragment>
            {/* <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteService}
                onCloseClick={() => setDeleteModal(false)}
            /> */}
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="User Orders" breadcrumbItem="Orders List" />
                    <Row>
                        <Col lg="12">
                            {!isLoading && (
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">User Orders</h5>
                                            {/* <div className="flex-shrink-0">
                                                <Link to="#"
                                                    onClick={() => {
                                                        // console.log('add click')
                                                        setIsEdit(false)
                                                        setModal(true)
                                                    }
                                                        // updateState({ isEdit: true })
                                                    }
                                                    className="btn btn-primary me-1">Add Package
                                                </Link>
                                                <Link to="" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                                <UncontrolledDropdown className="dropdown d-inline-block me-1">

                                                </UncontrolledDropdown>
                                            </div> */}
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <TableContainer
                                            columns={columns}
                                            data={orders}
                                            // data={dummyData}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            // handleJobClicks={handleJobClicks}
                                            // handleJobClicks={handleCategoryClicks}
                                            // isJobListGlobalFilter={true}
                                            customPageSize={10}
                                        />
                                        {orders?.length == 0 && !isLoading && (
                                            <div className="p-5">
                                                <div className="text-center ">
                                                    <i
                                                        className="bx bx-search"
                                                        style={{ fontSize: "30px" }}
                                                    ></i>
                                                </div>
                                                <div className="text-center mt-3">No Orders Found</div>
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {/* {!!isEdit ? "Edit Category" : "Add Category"} */}
                            {isEdit == true ? "Edit Order  Status" : "Add Package"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                // onSubmit={(e) => {
                                //     e.preventDefault();
                                //     console.log("object")
                                //     validation.handleSubmit();
                                //     return false;
                                // }}
                                onSubmit={handleSubmit}
                            >
                                <Row>
                                    <Col className="col-12">
                                        {/* <div className="mb-3">
                                            <Label className="form-label"> Choose Caregory</Label>
                                            <Input
                                                name="category"
                                                type="select"
                                                value={selectedCategory}
                                                // onChange={validation.handleChange}
                                                onChange={(e) => {
                                                    console.log(e.target.value, 'handleCategorySelect')
                                                    handleCategorySelect(e.target.value)
                                                }
                                                }
                                            >
                                                <option value="" disabled>Select a service</option>
                                                {categories?.map(category => (
                                                    <option key={category._id} value={category._id}>
                                                        {category?.categoryName}
                                                    </option>
                                                ))}
                                            </Input>
                                        </div> */}

                                        <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                value={formState.status}
                                                onChange={(e) =>
                                                    setFormState({ ...formState, status: e.target.value })
                                                }
                                            >
                                                <option value="" disabled>Select a service</option>
                                                <option value="Order Placed">Order Placed</option>
                                                <option value="Processing">Processing</option>
                                                <option value="Completed">Completed</option>
                                                <option value="Cancelled">Cancelled</option>
                                                <option value="Refunded">Refunded</option>
                                            </Input>
                                            {validationErrors.status && (
                                                <div className="text-danger">{validationErrors.status}</div>
                                            )}
                                        </div>

                                        {/* <div className="mb-3">
                                            <Label className="form-label">Package Name</Label>
                                            <Input
                                                name="packageName"
                                                type="textarea"
                                                placeholder="Package Name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.packageName || ""}
                                                invalid={
                                                    validation.touched.packageName && validation.errors.packageName ? true : false
                                                }
                                            />
                                            {validation.touched.packageName && validation.errors.packageName ? (
                                                <FormFeedback type="invalid">{validation.errors.packageName}</FormFeedback>
                                            ) : null}
                                        </div> */}
                                        {/* <div className="mb-3">
                                            <Label className="form-label">Package Days</Label>
                                            <Input
                                                name="packageDays"
                                                type="text"
                                                placeholder="packageDays"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.packageDays || ""}
                                                invalid={
                                                    validation.touched.packageDays && validation.errors.packageDays ? true : false
                                                }
                                            />
                                            {validation.touched.packageDays && validation.errors.packageDays ? (
                                                <FormFeedback type="invalid">{validation.errors.packageDays}</FormFeedback>
                                            ) : null}
                                        </div> */}

                                        {/* <div className="mb-3">
                                            <Label className="form-label"> Package Task</Label>
                                            <Input
                                                name="packageTask"
                                                type="text"
                                                placeholder="Enter packageTask"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.packageTask || ""}
                                                invalid={
                                                    validation.touched.packageTask && validation.errors.packageTask ? true : false
                                                }
                                            />
                                            {validation.touched.packageTask && validation.errors.packageTask ? (
                                                <FormFeedback type="invalid">{validation.errors.packageTask}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label"> Package Amount</Label>
                                            <Input
                                                name="packageAmount"
                                                type="text"
                                                placeholder="Enter packageAmount"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.packageAmount || ""}
                                                invalid={
                                                    validation.touched.packageAmount && validation.errors.packageAmount ? true : false
                                                }
                                            />
                                            {validation.touched.packageAmount && validation.errors.packageAmount ? (
                                                <FormFeedback type="invalid">{validation.errors.packageAmount}</FormFeedback>
                                            ) : null}
                                        </div> */}

                                        {/* <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.status || ""
                                                }
                                                invalid={
                                                    validation.touched.status && validation.errors.status ? true : false
                                                }
                                            >
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </Input>
                                            {validation.touched.status && validation.errors.status ? (
                                                <FormFeedback status="invalid">{validation.errors.status}</FormFeedback>
                                            ) : null}
                                        </div> */}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            // onClick={onaddPackage()}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </React.Fragment >
    );
}


export default OrderManagement;