import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
// import { jobs } from "../../../common/data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getCategorylist as onGetCategoryList,
} from "store/actions";

import { ImageCell } from "./tableListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

// import { deleteCategory } from "../../../helpers/smm_helper";
import AdminContext from '../../../context/adminContext';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
// import { BASE_URL } from "utils/api";

function HomePageManage() {
    const context = useContext(AdminContext);
    const { API_URL, GetIntroCMSData, welcomeIntro } = context;
    const EditorApiKey = process.env.REACT_APP_EDITOR_KEY

    useEffect(() => {
        GetIntroCMSData();
    }, []);

    // port
    // const host2 = process.env.REACT_APP_HOST || "http://localhost:8000"
    const host = process.env.REACT_APP_HOST
    // const host = "http://localhost:8001/api/v2"

    //meta title
    document.title = "Home Page CMS";

    const [modal, setModal] = useState(false);
    const [State, SetState] = useState(null);

    const [Image, setImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImagechange = (e) => {
        setImage(e.target.files[0]);
        const file = e.target.files[0];
        console.log(file, "home Page Image")
        updateState({ welcomeImage: file })
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            // Additional logic if needed
        }
    }
    const handleRemoveImage = () => {
        setSelectedImage(null);
    };

    const [state, setState] = useState({
        editId: null,
        isLoading: false,
        delete_id: null,
        edit: null,
        welcomeHeading: '', welcomeText: '', welcomeDec: '', welcomeImage: null, youtubeUrl: '',
    })
    const {
        // editId,
        isLoading,
        delete_id,
        // isEdit,
        edit,
        welcomeHeading, welcomeText, welcomeDec, welcomeImage, youtubeUrl,
        fbLink, youtubeLink, instaLink,
        address, email, mobile,
    } = state

    const updateState = data => setState(state => ({ ...state, ...data }))

    const handleServiceSelect = (serviceId) => {
        // console.log(serviceId, 'handleserviceSelect')
        setSelectedService(serviceId);
    };

    const toggle = () => {
        if (modal) {
            setModal(false);
            SetState(null)
        } else {
            setModal(true);
        }
    };

    const handleEditClick = arg => {
        // console.log(arg, 'arg')
        toggle();
        setIsEdit(true)
         setImage(arg.image)
        setSelectedImage(arg.image)
        updateState({
            welcomeHeading: arg.welcomeHeading,
            welcomeText: arg.welcomeText,
            welcomeDec: arg.welcomeDec,
            welcomeImage: arg.welcomeImage,
            youtubeUrl: arg.youtubeUrl,
            fbLink: arg.fbLink, youtubeLink: arg.youtubeLink, instaLink: arg.instaLink,
            address: arg.address, email: arg.email, mobile: arg.mobile,
        })
        setIsEdit(true);
        toggle();
    };

    //delete category
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (data) => {
        console.log("onClickDelete", data)
        // setEditcategory(data);
        setDeleteModal(true);
    };

    // Handle deleting a category
    const handleDeleteService = async () => {
        try {
            const response = await axios.delete(`${host}/package/delete-package/${delete_id}`);
            if (response.data.success == true) {
                // dispatch(onGetCategoryList());
                toast("Successfully Deleted package!", {
                    type: "success",
                });
                // Fetch services after deleting
                GetAllPackages();
                setDeleteModal(false);
            } else {
                toast("Failed to Deleted package", {
                    type: "error",
                });
            }
        } catch (error) {
            console.error('Error Deleting package:', error.message);
            toast("Error Deleting package:", {
                type: "error",
            });
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: 'No',
                accessor: (row, index) => index + 1, // Auto-incremented number
                Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
                filterable: false,
            },
            {
                Header: 'Welcome Text',
                accessor: 'welcomeText',
                filterable: true,
            },
            {
                Header: 'welcome Heading',
                accessor: 'welcomeHeading',
                filterable: true,
            },
            {
                Header: 'Welcome Dec',
                accessor: 'welcomeDec',
                filterable: true,
                // Cell: ({ value }) => <div style={{ textAlign: 'center2', width: '400px' }}>{value}</div>
            },
            {
                Header: 'Youtube Video Url',
                accessor: 'youtubeUrl',
                filterable: true,
                // Cell: ({ value }) => <div style={{ textAlign: 'center2', width: '400px' }}>{value}</div>
            },
            {
                Header: 'welcome Image',
                accessor: 'image',
                filterable: true,
                // Cell: ({ value }) => <div style={{ textAlign: 'center2', width: '400px' }}>{value}</div>
            },
            {
                Header: 'Footer Address',
                accessor: 'address',
                filterable: true,
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterable: true,
            },
            {
                Header: 'Mobile NO.',
                accessor: 'mobile',
                filterable: true,
            },
            {
                Header: 'Insta',
                accessor: 'instaLink',
                filterable: true,
            },
            {
                Header: 'Facebook',
                accessor: 'fbLink',
                filterable: true,
            },
            {
                Header: 'Youtube',
                accessor: 'youtubeLink',
                filterable: true,

            },
            {
                Header: 'Action',
                accessor: '_id',
                disableFilters: true,
                Cell: (cellProps) => {
                    const data = cellProps.data.find(elem => elem._id === cellProps.value)
                    // console.log(data, 'data')
                    // console.log(data._id)
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link to="#" className="btn btn-sm btn-soft-primary">
                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                ViewUncontrolledTooltip
                            </UncontrolledTooltip> */}
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    // onClick={() => {
                                    //     // const categoryData = cellProps.row.original;
                                    //     // handleEditClick(categoryData);
                                    // }}
                                    onClick={() => {
                                        setIsEdit(true)
                                        // toggle();
                                        setModal(true)
                                        handleEditClick(data);
                                        setEditId(data._id)
                                        // updateState({
                                        //     isEdit: true,
                                        //     // edit: data,
                                        //     service: data.service, packageName: data.packageName,
                                        //     packageDays: data.packageDays, packageTask: data.packageTask,
                                        //     packageAmount: data.packageAmount, status: data.status,
                                        // })
                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            {/* <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    // onClick={() => {
                                    //     // const categoryId = cellProps.row.original._id;
                                    //     onClickDelete(cellProps.value);
                                    // }}
                                    onClick={() => {
                                        setDeleteModal(true);
                                        updateState({
                                            // isDelete: true,                                       
                                            delete_id: cellProps.value,
                                        })
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li> */}
                        </ul>
                    );
                }
            },
        ],
        []
    );

    const [formState, setFormState] = useState({
        service: '',
        packageName: '',
        packageDays: '',
        packageAmount: '',
        packageTask: '',
        status: 'Active',
    });

    const [validationErrors, setValidationErrors] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState(null);

    const validateForm = () => {
        const errors = {};

        if (!selectedService) {
            errors.service = 'Please select a service';
        }

        if (!formState.packageName) {
            errors.packageName = 'Please enter package name';
        }

        if (!formState.packageDays) {
            errors.packageDays = 'Please enter package days';
        }

        if (!formState.packageAmount) {
            errors.packageAmount = 'Please enter package amount';
        }

        if (!formState.packageTask) {
            errors.packageTask = 'Please enter package task';
        }

        if (!formState.status) {
            errors.status = 'Please select a status';
        }

        setValidationErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate the form
        // const isValid = validateForm();
        // if (isValid) {
        // console.log("object", Image)

        try {
            const formData = new FormData();
            formData.append('welcomeHeading', welcomeHeading);
            formData.append('welcomeText', welcomeText);
            formData.append('welcomeDec', welcomeDec);
            formData.append('image', Image);
            formData.append('youtubeUrl', youtubeUrl);
            formData.append('fbLink', fbLink);
            formData.append('instaLink', instaLink);
            formData.append('youtubeLink', youtubeLink);
            formData.append('address', address);
            formData.append('email', email);
            formData.append('mobile', mobile);
            // let url = isEdit ? `${host}/cms/edit-intros/${editId}` : `${host}/cms/add-intros`;
            const url = `${host}/cms/edit-intros/${editId}`;
            // const url = `http://localhost:8001/api/v2/cms/edit-intros/${editId}`;

            const response = await axios({
                // method: isEdit ? 'put' : 'post',
                method: 'put',
                url,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                // Reset form and perform other actions
                console.log(isEdit ? 'Updated !' : 'Added !');
                toast(`Successfully ${isEdit ? 'updated' : 'added'} Intro !`, {
                    type: 'success',
                });
                // refresh data
                GetIntroCMSData();
                toggle();
                setIsEdit(false);
                setEditId(null);
            } else {
                toast(`Failed to ${isEdit ? 'update' : 'add'} Data`, {
                    type: 'error',
                });
            }
        } catch (error) {
            console.error(`Error ${isEdit ? 'updating' : 'creating'} CMS Data:`, error.message);
            toast(`Error ${isEdit ? 'updating' : 'creating'} CMS Data`, {
                type: 'error',
            });
        }
        // } else {
        //     console.log('Form validation failed');
        // }
    };


    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteService}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <div className="container-fluid">
                    {/* <Breadcrumbs title="Home Page CMS" breadcrumbItem="Home Page CMS" /> */}
                    <Row>
                        <Col lg="12">
                            {!isLoading && (
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Home Page CMS</h5>
                                            <div className="flex-shrink-0">
                                                {/* <Link to="#"
                                                    onClick={() => {
                                                        // console.log('add click')
                                                        setIsEdit(false)
                                                        setModal(true)
                                                    }
                                                        // updateState({ isEdit: true })
                                                    }
                                                    className="btn btn-primary me-1">Add Website Data
                                                </Link>
                                                <Link to="" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link> */}
                                                <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                    {/* <DropdownToggle type="menu" className="btn btn-success" id="dropdownMenuButton1">
                                                        <i className="mdi mdi-dots-vertical"></i></DropdownToggle> */}
                                                    {/* <DropdownMenu>
                                                        <li><DropdownItem href="#">Edit</DropdownItem></li>
                                                        <li><DropdownItem href="#">Delete</DropdownItem></li>
                                                        <li><DropdownItem href="#">Disable</DropdownItem></li>
                                                    </DropdownMenu> */}
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <TableContainer
                                            columns={columns}
                                            data={welcomeIntro}
                                            // data={dummyData}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            // handleJobClicks={handleJobClicks}
                                            // handleJobClicks={handleCategoryClicks}
                                            // isJobListGlobalFilter={true}
                                            customPageSize={10}
                                        />
                                        {welcomeIntro?.length == 0 && !isLoading && (
                                            <div className="p-5">
                                                <div className="text-center ">
                                                    <i
                                                        className="bx bx-search"
                                                        style={{ fontSize: "30px" }}
                                                    ></i>
                                                </div>
                                                <div className="text-center mt-3">No Data Found</div>
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                    <Modal isOpen={modal} toggle={toggle} size="lg">
                        <ModalHeader toggle={toggle} tag="h4">
                            {/* {!!isEdit ? "Edit Category" : "Add Category"} */}
                            {isEdit == true ? "Edit Home Page CMS" : "Add "}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                // onSubmit={(e) => {
                                //     e.preventDefault();
                                //     console.log("object")
                                //     validation.handleSubmit();
                                //     return false;
                                // }}
                                onSubmit={handleSubmit}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label className="form-label">Welcome Heading</Label>
                                            <Editor
                                                initialValue={welcomeHeading} // Pass the initial value
                                                apiKey={EditorApiKey} // Pass your TinyMCE API key
                                                init={{
                                                    height: 200,
                                                    width: '100%',
                                                    // TinyMCE configuration options
                                                    plugins: 'autolink lists link', // Specify desired plugins
                                                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link',
                                                    // Other configuration options...
                                                }}
                                                onEditorChange={(content) => {
                                                    updateState({ welcomeHeading: content }); // Update state when the editor content changes
                                                }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Welcome Text</Label>
                                            <Editor
                                                initialValue={welcomeText} // Pass the initial value
                                                apiKey={EditorApiKey} // Pass your TinyMCE API key
                                                init={{
                                                    height: 200,
                                                    width: '100%',
                                                    // TinyMCE configuration options
                                                    plugins: 'autolink lists link', // Specify desired plugins
                                                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link',
                                                    // Other configuration options...
                                                }}
                                                onEditorChange={(content) => {
                                                    updateState({ welcomeText: content }); // Update state when the editor content changes
                                                }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Welcome Description</Label>
                                            <Editor
                                                initialValue={welcomeDec} // Pass the initial value
                                                apiKey={EditorApiKey} // Pass your TinyMCE API key
                                                init={{
                                                    height: 200,
                                                    width: '100%',
                                                    // TinyMCE configuration options
                                                    plugins: 'autolink lists link', // Specify desired plugins
                                                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link',
                                                    // Other configuration options...
                                                }}
                                                onEditorChange={(content) => {
                                                    updateState({ welcomeDec: content }); // Update state when the editor content changes
                                                }}
                                            />
                                        </div>
                                        {/* <div className="mb-3">
                                            <Label className="form-label">Welcome Heading</Label>
                                            <Input
                                                name="packageName"
                                                type="text"
                                                placeholder="Package Name"
                                                // value={formState.packageName}
                                                value={welcomeHeading}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    updateState({ welcomeHeading: selectedValue });
                                                }}
                                            // onChange={(e) =>
                                            //     setFormState({ ...formState, packageName: e.target.value })
                                            // }
                                            // onBlur={() => handleBlur('packageName')}
                                            />
                                            {validationErrors.welcomeHeading && (
                                                <div className="text-danger">{validationErrors.welcomeHeading}</div>
                                                // <FormFeedback type="invalid">{validationErrors.packageName}</FormFeedback>
                                            )}
                                        </div> */}
                                        {/* <div className="mb-3">
                                            <Label className="form-label">Welcome Text</Label>
                                            <Input
                                                name="packageDays"
                                                type="text"
                                                placeholder="package Days"
                                                value={welcomeText}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    updateState({ welcomeText: selectedValue });
                                                }}
                                            // value={formState.packageDays}
                                            // onChange={(e) =>
                                            //     setFormState({ ...formState, packageDays: e.target.value })
                                            // }
                                            />
                                            {validationErrors.packageDays && (
                                                <div className="text-danger">{validationErrors.packageDays}</div>
                                                // <FormFeedback type="invalid">{validationErrors.packageDays}</FormFeedback>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">welcome Description</Label>
                                            <Input
                                                name="packageTask"
                                                type="text"
                                                placeholder="Package Task"
                                                // value={formState.packageTask}
                                                value={welcomeDec}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    updateState({ welcomeDec: selectedValue });
                                                }}
                                            // onChange={(e) =>
                                            //     setFormState({ ...formState, packageTask: e.target.value })
                                            // }
                                            />
                                            {validationErrors.packageTask && (
                                                <div className="text-danger">{validationErrors.packageTask}</div>
                                                // <FormFeedback type="invalid">{validationErrors.packageTask}</FormFeedback>
                                            )}
                                        </div> */}
                                        <div className="mb-3">
                                            <Label className="form-label">Youtube Url</Label>
                                            <Input
                                                name="YoutubeUrl"
                                                type="text"
                                                placeholder="https://www.youtube.com/watch?v=hAP2QF--2Dg"
                                                // value={formState.packageAmount}
                                                value={youtubeUrl}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    updateState({ youtubeUrl: selectedValue });
                                                }}
                                            // onChange={(e) =>
                                            //     setFormState({ ...formState, packageAmount: e.target.value })
                                            // }
                                            />
                                            {/* {validationErrors.packageAmount && (
                                                <div className="text-danger">{validationErrors.packageAmount}</div>
                                                // <FormFeedback type="invalid">{validationErrors.packageAmount}</FormFeedback>
                                            )} */}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Home Page Image</Label>
                                            <Input
                                                name="home_page_image"
                                                type="file"
                                                placeholder="Select Image"
                                                onChange={(e) => { handleImagechange(e); }}
                                            />
                                            {selectedImage && (
                                                <div className="mt-2 d-flex align-items-center">
                                                    <img
                                                        src={selectedImage}
                                                        alt="Selected Icon"
                                                        style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline btn-sm"
                                                        onClick={handleRemoveImage}
                                                        style={{ fontSize: '25px' }}
                                                    >
                                                        {/* <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                                                            <i className="mdi mdi-trash-can-outline"></i>
                                                        </div> */}
                                                        <button type="button"
                                                            className="btn-close"></button>
                                                    </button>
                                                </div>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label">Address:</Label>
                                            <Input
                                                name="address"
                                                type="text"
                                                placeholder="Enter Your Addres For Footer"
                                                value={address}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    updateState({ address: selectedValue });
                                                }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Email:</Label>
                                            <Input
                                                name="email"
                                                type="text"
                                                placeholder="Enter your email address"
                                                value={email}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    updateState({ email: selectedValue });
                                                }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Mobile Number:</Label>
                                            <Input
                                                name="mobile"
                                                type="Number"
                                                placeholder="Enter your Mobile No."
                                                value={mobile}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    updateState({ mobile: selectedValue });
                                                }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Facebook ID:</Label>
                                            <Input
                                                name="fbLink"
                                                type="text"
                                                placeholder="Enter Your Facebook Profile URL"
                                                value={fbLink}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    updateState({ fbLink: selectedValue });
                                                }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Insta ID:</Label>
                                            <Input
                                                name="instaLink"
                                                type="text"
                                                placeholder="Enter Your Insta Profile URL"
                                                value={instaLink}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    updateState({ instaLink: selectedValue });
                                                }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Youtube Channel:</Label>
                                            <Input
                                                name="youtubeLink"
                                                type="text"
                                                placeholder="Enter Your Youtube Channel Url"
                                                value={youtubeLink}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    updateState({ youtubeLink: selectedValue });
                                                }}
                                            />
                                        </div>
                                        {/* <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.status || ""
                                                }
                                                invalid={
                                                    validation.touched.status && validation.errors.status ? true : false
                                                }
                                            >
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </Input>
                                            {validation.touched.status && validation.errors.status ? (
                                                <FormFeedback status="invalid">{validation.errors.status}</FormFeedback>
                                            ) : null}
                                        </div> */}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            // onClick={onaddPackage()}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </React.Fragment >
    );
}

export default HomePageManage;
