import {
    GET_CATEGORY_LIST,
    GET_CATEGORY_LIST_SUCCESS,
    GET_CATEGORY_LIST_FAIL,

} from "./actionTypes";

export const getCategorylist = () => ({
    type: GET_CATEGORY_LIST,
})
export const getCategorylistSuccess = category => ({
    type: GET_CATEGORY_LIST_SUCCESS,
    payload: category,
})
export const getCategorylistFail = error => ({
    type: GET_CATEGORY_LIST_FAIL,
    payload: error,
})

// export const getJobListSuccess = jobs => ({
//   type: GET_JOB_LIST_SUCCESS,
//   payload: jobs,
// })

// export const getJobListFail = error => ({
//   type: GET_JOB_LIST_FAIL,
//   payload: error,
// })

// export const addNewJobList = data => ({
//   type: ADD_NEW_JOB_LIST,
//   payload: data,
// })

// export const addJobListSuccess = job => ({
//   type: ADD_JOB_LIST_SUCCESS,
//   payload: job,
// })

// export const addJobListFail = error => ({
//   type: ADD_JOB_LIST_FAIL,
//   payload: error,
// })

// export const updateJobList = job => ({
//   type: UPDATE_JOB_LIST,
//   payload: job,
// })

// export const updateJobListSuccess = job => ({
//   type: UPDATE_JOB_LIST_SUCCESS,
//   payload: job,
// })

// export const updateJobListFail = error => ({
//   type: UPDATE_JOB_LIST_FAIL,
//   payload: error,
// })

// export const deleteJobList = data => ({
//   type: DELETE_JOB_LIST,
//   payload: data,
// })

// export const deleteJobListSuccess = data => ({
//   type: DELETE_JOB_LIST_SUCCESS,
//   payload: data,
// })

// export const deleteJobListFail = error => ({
//   type: DELETE_JOB_LIST_FAIL,
//   payload: error,
// })

// export const getApplyJob = () => ({
//   type: GET_APPLY_JOB,
// })

// export const getApplyJobSuccess = data => ({
//   type: GET_APPLY_JOB_SUCCESS,
//   payload: data
// })

// export const getApplyJobFail = error => ({
//   type: GET_APPLY_JOB_FAIL,
//   payload: error
// })

// export const deleteApplyJob = data => ({
// type: DELETE_APPLY_JOB,
// payload: data
// })

// export const deleteApplyJobSuccess = data => ({
// type: DELETE_APPLY_JOB_SUCCESS,
// payload: data
// })

// export const deleteApplyJobFail = error => ({
// type: DELETE_APPLY_JOB_FAIL,
// payload: error
// })