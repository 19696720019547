import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
// import { jobs } from "../../../common/data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getCategorylist as onGetCategoryList,
} from "store/actions";

import { ImageCell } from "./serviceListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import { getCategories, addCategory, editCategory, deleteCategory } from "../../../helpers/smm_helper";
import AdminContext from '../../../context/adminContext';
import axios from 'axios';

function ServiceList() {
    const context = useContext(AdminContext);
    const { services, categories, GetAllServices, GetAllCategories } = context;

    useEffect(() => {
        GetAllCategories();
        GetAllServices();
    }, []);

    // port
    const host = process.env.REACT_APP_HOST
    // const host = "http://localhost:8001/api/v2"
    //meta title
    document.title = "Service Management|";

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    // const [editID, setEditId] = useState('');
    const [categoryData, setCategoryData] = useState([])
    const [editCategory, setEditcategory] = useState(null);
    const [State, SetState] = useState(null);
    const [serviceIcon, setServiceIcon] = useState(null);
    const handleImagechange = (e) => {
        setServiceIcon(e.target.files[0]);
    }
    // const [preview, setPreview] = useState("")
    // useEffect(() => {
    //     if (categoryIcon) {
    //         // setImgdata("")
    //         setPreview(URL.createObjectURL(categoryIcon))
    //     }
    //     // setTimeout(() => {
    //     //   setShowSpin(false)
    //     // }, 1200)
    // }, [categoryIcon])

    const [state, setState] = useState({
        editId: null,
        isLoading: false,
        delete_id: null,
        edit: null,
        //   category:"",
        //   serviceName:"",
        //   description:"",
        //   serviceIcon:"",
        //   status:"",
    })
    const {
        editId,
        isLoading,
        delete_id,
        // isEdit,
        edit,
        //   category,
        //   serviceName,
        //   description,
        //   serviceIcon,
        // status,
    } = state

    const updateState = data => setState(state => ({ ...state, ...data }))
    const [selectedCategory, setSelectedCategory] = useState('');

    // Handle category selection
    const handleCategorySelect = (categoryId) => {
        console.log(categoryId, 'handleCategorySelect')
        setSelectedCategory(categoryId);
    };

    const [formState, setFormState] = useState({
        // category: '', not in use
        // serviceName: '',
        // serviceIcon: '',
        // description: '',
        status: 'Active',
    });

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            // Id: (State && State.Id) || '',
            category: (selectedCategory) || '',
            serviceName: (State && State.serviceName) || '',
            // serviceIcon: (State && State.categoryIcon) || null,
            description: (State && State.description) || '',
            status: (State && State.status) || '',
        },
        validationSchema: Yup.object({
            serviceName: Yup.string().required("Please Enter serviceName Name"),
            // serviceIcon: Yup.string().required("Please Select Icon"),
            description: Yup.string().required("Please Enter Description"),
        }),
        onSubmit: async (values) => {
            // console.log(values, "onSubmit")
            // if when click on edit button setEdit will be true then edit api will call  
            if (isEdit == true) {
                const editId = State.editId
                try {
                    const selectedCategoryId2 = selectedCategory
                    const formData = new FormData();
                    formData.append('category', selectedCategoryId2);
                    formData.append('serviceName', values.serviceName);
                    formData.append('description', values.description);
                    formData.append('serviceIcon', serviceIcon);
                    formData.append('status', formState.status);
                    // formData.append('status', values.status);
                    const response = await axios.put(`${host}/service/update-service/${editId}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: localStorage.getItem("token"),
                        },
                    });

                    if (response.data.success == true) {
                        GetAllServices();
                        toast("Successfully updated service!", {
                            type: "success",
                        });
                        toggle();
                        validation.resetForm();
                        setServiceIcon(null)
                        // setIsEdit(false)
                    } else {
                        toast("Failed to update service", {
                            type: "error",
                        });
                    }
                } catch (error) {
                    console.error('Error updating service:', error.message);
                    toast("Error updating service", {
                        type: "error",
                    });
                }
            } else {
                try {
                    // storing category ID in selectedCategoryId from state selectedCategory
                    const selectedCategoryId = selectedCategory
                    const formData = new FormData();
                    formData.append('category', selectedCategoryId);
                    formData.append('serviceName', values.serviceName);
                    formData.append('description', values.description);
                    formData.append('serviceIcon', serviceIcon);
                    // formData.append('status', values.status);
                    formData.append('status', formState.status);
                    axios.post(`${host}/service/create-service`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: localStorage.getItem("token"),
                        },
                    })
                        .then((response) => {
                            // console.log(response.data);
                            if (response.data.success == true) {
                                GetAllServices();
                                toast("Successfully added service!", {
                                    type: "success",
                                });
                                toggle();
                                validation.resetForm();
                                setServiceIcon(null)
                            } else {
                                toast("Failed to add service", {
                                    type: "error",
                                });
                            }
                        })
                        .catch((error) => {
                            console.error('Error creating service:', error.message);
                            toast("Error creating category", {
                                type: "error",
                            });
                        });
                } catch (error) {
                    console.error('Error creating service:', error.message);
                    toast("Error creating service", {
                        type: "error",
                    });
                }

            }
        }
    });

    // const dispatch = useDispatch();

    // const { categoryList } = useSelector(state => ({
    //     categoryList: state.CategoryReducer.categoryList,
    // }));

    // useEffect(() => {
    //     // Fetch category list only if it's not already loaded
    //     if (!categoryList || !categoryList.categories || !categoryList.categories.length) {
    //         dispatch(onGetCategoryList());
    //     }
    // }, [categoryList, dispatch]);

    // useEffect(() => {
    //     // Update local state when categoryList.categories changes
    //     if (categoryList && categoryList.categories) {
    //         setCategoryData(categoryList.categories);
    //     }
    // }, [categoryList]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            SetState(null)
            setEditcategory(null)
        } else {
            setModal(true);
        }
    };

    // const [xid, setxid] = useState('')
    const handleEditClick = arg => {
        // console.log(arg, 'arg')
        toggle();
        setIsEdit(true)
        // setxid(arg._id)
        // const data = arg;
        setSelectedCategory(arg.category._id)
        SetState({
            editId: arg._id,
            // Id: arg._id,:
            category: arg.category,
            serviceName: arg.serviceName,
            description: arg.description,
            serviceIcon: arg.serviceIcon,
            status: arg.status,
        });
        setIsEdit(true);
        toggle();
    };

    const handleCategoryClicks = () => {
        setCategoryData("");
        setIsEdit(false);
        toggle();
    };


    //delete category
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (data) => {
        console.log("onClickDelete", data)
        setEditcategory(data);
        setDeleteModal(true);
    };


    // Handle deleting a service
    const handleDeleteService = async () => {
        try {
            const response = await axios.delete(`${host}/service/delete-service/${delete_id}`, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            });
            if (response.data.success == true) {
                // dispatch(onGetCategoryList());
                toast("Successfully Deleted service!", {
                    type: "success",
                });
                // Fetch services after deleting
                GetAllServices();
                setDeleteModal(false);
            } else {
                toast("Failed to Deleted service", {
                    type: "error",
                });
            }
        } catch (error) {
            console.error('Error Deleting service:', error.message);
            toast("Error Deleting service:", {
                type: "error",
            });
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: 'No',
                accessor: (row, index) => index + 1, // Auto-incremented number
                Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
                filterable: false,
            },
            {
                Header: 'Category Name',
                accessor: 'category',
                filterable: true,
                // Cell: ({ value }) => {
                //     //  `value` is the category ID
                //     const filteredCategory = categories.find((category) => category._id === value);
                //     return filteredCategory ? filteredCategory.categoryName : 'Loading...';
                // }
                Cell: ({ value }) => {
                    // console.log(value, 'value')
                    let Value = value?.categoryName;
                    return Value;
                }
            },
            {
                Header: 'Service Name',
                accessor: 'serviceName',
                filterable: true,
            },
            {
                Header: 'Service Icon',
                accessor: 'serviceIcon',
                filterable: true,
                Cell: ({ cell: { value } }) => {
                    // console.log(value, 'icon');
                    return <ImageCell cell={{ value }} />;
                },
            },
            {
                Header: 'Deccription',
                accessor: 'description',
                filterable: true,
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterable: true,
            },
            {
                Header: 'createdAt',
                accessor: 'createdAt',
                Cell: ({ value }) => {
                    const localDate = new Date(value).toLocaleString();
                    return localDate;
                }
            },
            {
                Header: 'updatedAt',
                accessor: 'updatedAt',
                disableFilters: true,
                Cell: ({ value }) => {
                    const localDate = new Date(value).toLocaleString();
                    return localDate;
                }
            },
            {
                Header: 'Action',
                accessor: '_id',
                disableFilters: true,
                Cell: (cellProps) => {
                    const data = cellProps.data.find(elem => elem._id === cellProps.value)
                    // console.log(data)
                    // console.log(data._id)
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link to="#" className="btn btn-sm btn-soft-primary">
                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                ViewUncontrolledTooltip
                            </UncontrolledTooltip> */}
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    // onClick={() => {
                                    //     // const categoryData = cellProps.row.original;
                                    //     // handleEditClick(categoryData);
                                    // }}
                                    onClick={() => {
                                        setIsEdit(true)
                                        setModal(true)
                                        handleEditClick(data);
                                        // setEditId(data._id)
                                        // updateState({
                                        //     isEdit: true,
                                        //     edit: data,
                                        // })
                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    // onClick={() => {
                                    //     // const categoryId = cellProps.row.original._id;
                                    //     onClickDelete(cellProps.value);
                                    // }}
                                    onClick={() => {
                                        setDeleteModal(true);
                                        updateState({
                                            // isDelete: true,
                                            delete_id: cellProps.value,
                                        })
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );

    const Reload = () => {
        window.location.reload()
    }

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteService}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Service Management" breadcrumbItem="Service List" />
                    <Row>
                        <Col lg="12">
                            {!isLoading && (
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Service Management</h5>
                                            <div className="flex-shrink-0">
                                                <Link to="#!" className="btn btn-light me-1" onClick={Reload}><i className="mdi mdi-refresh"></i></Link>
                                                <Link to="#"
                                                    onClick={() => {
                                                        setIsEdit(false)
                                                        setModal(true)
                                                    }
                                                        // updateState({ isEdit: true })
                                                    }
                                                    className="btn btn-primary me-1">Add Service
                                                </Link>

                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <TableContainer
                                            columns={columns}
                                            data={services}
                                            // data={dummyData}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            // handleJobClicks={handleJobClicks}
                                            // handleJobClicks={handleCategoryClicks}
                                            // isJobListGlobalFilter={true}
                                            customPageSize={20}
                                        />
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {/* {!!isEdit ? "Edit Category" : "Add Category"} */}
                            {isEdit == true ? "Edit Service  " : "Add Service "}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label className="form-label"> Choose Category</Label>
                                            <Input
                                                name="category"
                                                type="select"
                                                value={selectedCategory}
                                                // onChange={validation.handleChange}
                                                onChange={(e) => handleCategorySelect(e.target.value)}
                                            // onBlur={validation.handleBlur}
                                            // value={validation.values.category || ""}
                                            // invalid={validation.touched.category && validation.errors.category ? true : false}
                                            >
                                                <option value="" disabled>Select a category</option>
                                                {categories?.map(category => (
                                                    <option key={category._id} value={category._id}>
                                                        {category.categoryName}
                                                    </option>
                                                ))}
                                            </Input>
                                            {/* {validation.touched.category && validation.errors.category ? (
                                                <FormFeedback status="invalid">{validation.errors.category}</FormFeedback>
                                            ) : null} */}
                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label">Service Name</Label>
                                            <Input
                                                name="serviceName"
                                                type="text"
                                                placeholder="Service Name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.serviceName || ""}
                                                invalid={
                                                    validation.touched.serviceName && validation.errors.serviceName ? true : false
                                                }
                                            />
                                            {validation.touched.serviceName && validation.errors.serviceName ? (
                                                <FormFeedback type="invalid">{validation.errors.serviceName}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Service Icon</Label>
                                            <Input
                                                name="serviceIcon"
                                                type="file"
                                                placeholder="Select Icon"
                                                // validate={{
                                                //     required: { value: true },
                                                // }}
                                                // onChange={validation.handleChange}
                                                onChange={(e) => { handleImagechange(e); }}
                                            // onBlur={validation.handleBlur}
                                            // value={validation.values.serviceIcon || ""}
                                            // invalid={
                                            //     validation.touched.serviceIcon && validation.errors.serviceIcon ? true : false
                                            // }
                                            />
                                            {validation.touched.serviceIcon && validation.errors.serviceIcon ? (
                                                <FormFeedback type="invalid">{validation.errors.serviceIcon}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label"> Description</Label>
                                            <Input
                                                name="description"
                                                type="text"
                                                placeholder="Enter Description"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.description || ""}
                                                invalid={
                                                    validation.touched.description && validation.errors.description ? true : false
                                                }
                                            />
                                            {validation.touched.description && validation.errors.description ? (
                                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                value={formState.status}
                                                onChange={(e) =>
                                                    setFormState({ ...formState, status: e.target.value })
                                                }
                                            >
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </Input>
                                            {/* {validationErrors.status && (
                                                <div className="text-danger">{validationErrors.status}</div>
                                            )} */}
                                        </div>
                                        {/* <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.status || ""
                                                }
                                                invalid={
                                                    validation.touched.status && validation.errors.status ? true : false
                                                }
                                            >
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </Input>
                                            {validation.touched.status && validation.errors.status ? (
                                                <FormFeedback status="invalid">{validation.errors.status}</FormFeedback>
                                            ) : null}
                                        </div> */}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    );
}


export default ServiceList;