import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
// import { jobs } from "../../../common/data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getCategorylist as onGetCategoryList,
} from "store/actions";

import { ImageCell } from "./packageListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

// import { deleteCategory } from "../../../helpers/smm_helper";
import AdminContext from '../../../context/adminContext';
import axios from 'axios';

function PackageManage() {
    const context = useContext(AdminContext);
    const { GetAllCategories, categories, services, GetAllServices, packages, GetAllPackages, API_URL } = context;

    useEffect(() => {
        GetAllCategories();
        GetAllPackages();
        GetAllServices();
    }, []);

    // port
    const host = process.env.REACT_APP_HOST
    // const host = "http://localhost:8001/api/v2"
    //meta title
    document.title = "Package Management| Package";

    const [modal, setModal] = useState(false);
    // const [isEdit, setIsEdit] = useState(null);
    // const [editID, setEditId] = useState('');
    const [categoryData, setCategoryData] = useState([])
    const [editCategory, setEditcategory] = useState(null);
    const [State, SetState] = useState(null);

    // const [serviceIcon, setServiceIcon] = useState(null);
    // const handleImagechange = (e) => {
    //     setServiceIcon(e.target.files[0]);
    // }

    const [state, setState] = useState({
        editId: null,
        isLoading: false,
        delete_id: null,
        edit: null,
        service: '', packageName: '', packageDays: '', packageTask: '', packageAmount: '', status: '',
    })
    const {
        // editId,
        isLoading,
        delete_id,
        // isEdit,
        edit,
        service, packageName, packageDays, packageTask, packageAmount, status,
    } = state

    const updateState = data => setState(state => ({ ...state, ...data }))

    // selected service state
    // const [selectedService, setSelectedService] = useState(service || '');
    // Handle category selection
    const handleServiceSelect = (serviceId) => {
        // console.log(serviceId, 'handleserviceSelect')
        setSelectedService(serviceId);
    };
    const handleCategorySelect = (categoryId) => {
        // console.log(categoryId, 'handlecategorySelect')
        setSelectedCategory(categoryId);
    };


    // const validation = useFormik({
    //     // enableReinitialize : use this flag when initial values needs to be changed
    //     enableReinitialize: true,
    //     initialValues: {
    //         service: (State && State.service) || '',
    //         packageName: (State && State.packageName) || '',
    //         packageDays: (State && State.packageDays) || '',
    //         packageTask: (State && State.packageTask) || '',
    //         packageAmount: (State && State.packageAmount) || '',
    //         status: (State && State.status) || '',
    //     },
    //     validationSchema: Yup.object({
    //         service: Yup.string().required("Please select serviceName "),
    //         // serviceIcon: Yup.string().required("Please Select Icon"),
    //         packageName: Yup.string().required("Please Enter package Name"),
    //         packageDays: Yup.string().required("Please Enter package Days"),
    //         packageAmount: Yup.string().required("Please Enter package Amount"),
    //         packageTask: Yup.string().required("Please Enter package Task"),
    //     }),
    //     onSubmit: async (values) => {
    //         // console.log(values, "onSubmit");
    //         console.log('onSubmit function called');
    //         console.log('Is Edit:', isEdit); // Add this line
    //         // if (isEdit == true) {
    //         //     console.log('Entered if block');

    //         // } else {
    //         //     console.log('Entered else block');
    //         // }
    //         if (isEdit == true) {
    //             const editId = State.editId;
    //             try {
    //                 const selectedServiceId = selectedService;
    //                 const formData = new FormData();
    //                 formData.append('service', selectedServiceId);
    //                 formData.append('packageName', values.packageName);
    //                 formData.append('packageDays', values.packageDays);
    //                 formData.append('packageAmount', values.packageAmount);
    //                 formData.append('packageTask', values.packageTask);
    //                 formData.append('status', values.status);

    //                 const response = await axios.put(
    //                     `${host}/package/update-package/${editId}`,
    //                     formData,
    //                     {
    //                         headers: {
    //                             'Content-Type': 'application/json',
    //                         },
    //                     }
    //                 );
    //                 if (response.data.success) {
    //                     GetAllPackages();
    //                     toast("Successfully updated Package!", {
    //                         type: "success",
    //                     });
    //                     toggle();
    //                     validation.resetForm();
    //                     setIsEdit(false)

    //                 } else {
    //                     toast("Failed to update Package", {
    //                         type: "error",
    //                     });
    //                 }
    //             } catch (error) {
    //                 console.error('Error updating Package:', error.message);
    //                 toast("Error updating Package", {
    //                     type: "error",
    //                 });
    //             }
    //         }
    //         else {
    //             console.log('Entered else block');
    //             // const selectedServiceId2 = selectedService;
    //             try {
    //                 const selectedServiceId2 = selectedService;
    //                 const formData = new FormData();
    //                 formData.append('service', selectedServiceId2);
    //                 formData.append('packageName', values.packageName);
    //                 formData.append('packageDays', values.packageDays);
    //                 formData.append('packageAmount', values.packageAmount);
    //                 formData.append('packageTask', values.packageTask);
    //                 formData.append('status', values.status);

    //                 const response = await axios.post(
    //                     `${host}/package/create-package`,
    //                     formData,
    //                     {
    //                         headers: {
    //                             'Content-Type': 'application/json',
    //                         },
    //                     }
    //                 );

    //                 if (response.data.success) {
    //                     GetAllPackages();
    //                     toast("Successfully added Package!", {
    //                         type: "success",
    //                     });
    //                     toggle();
    //                     validation.resetForm();
    //                 } else {
    //                     toast("Failed to add Package", {
    //                         type: "error",
    //                     });
    //                 }
    //             } catch (error) {
    //                 console.error('Error creating Package:', error.message);
    //                 toast("Error creating category", {
    //                     type: "error",
    //                 });
    //             }
    //         }
    //     }

    //     // onSubmit: async (values) => {
    //     //     console.log(values, "onSubmit")
    //     //     // if when click on edit button setEdit will be true then edit api will call  
    //     //     if (isEdit == true) {
    //     //         const editId = State.editId
    //     //         try {
    //     //             const selectedServiceId = selectedService
    //     //             const formData = new FormData();
    //     //             formData.append('service', selectedServiceId);
    //     //             formData.append('packageName', values.packageName);
    //     //             formData.append('packageDays', values.packageDays);
    //     //             formData.append('packageAmount', values.packageAmount);
    //     //             formData.append('packageTask', values.packageTask);
    //     //             formData.append('status', values.status);
    //     //             const response = await axios.put(`${host}/package/update-package/${editId}`, formData,
    //     //                 {
    //     //                     headers: {
    //     //                         'Content-Type': 'application/json',
    //     //                     },
    //     //                 }
    //     //             );

    //     //             if (response.data.success == true) {
    //     //                 GetAllPackages();
    //     //                 toast("Successfully updated Package!", {
    //     //                     type: "success",
    //     //                 });
    //     //                 toggle();
    //     //                 validation.resetForm();
    //     //             } else {
    //     //                 toast("Failed to update Package", {
    //     //                     type: "error",
    //     //                 });
    //     //             }
    //     //         } catch (error) {
    //     //             console.error('Error updating Package:', error.message);
    //     //             toast("Error updating Package", {
    //     //                 type: "error",
    //     //             });
    //     //         }
    //     //     }
    //     //     else {
    //     //         try {
    //     //             // storing service ID in selectedServiceId from state selectedservice
    //     //             const selectedServiceId2 = selectedService
    //     //             const formData = new FormData();
    //     //             formData.append('service', selectedServiceId2);
    //     //             formData.append('packageName', values.packageName);
    //     //             formData.append('packageDays', values.packageDays);
    //     //             formData.append('packageAmount', values.packageAmount);
    //     //             formData.append('packageTask', values.packageTask);
    //     //             formData.append('status', values.status);

    //     //             axios.post(`${host}/package/create-package`, formData,
    //     //                 {
    //     //                     headers: {
    //     //                         'Content-Type': 'application/json',
    //     //                     },
    //     //                 }
    //     //             )
    //     //                 .then((response) => {
    //     //                     console.log(response.data);
    //     //                     if (response.data.success == true) {
    //     //                         GetAllPackages();
    //     //                         toast("Successfully added Package!", {
    //     //                             type: "success",
    //     //                         });
    //     //                         toggle();
    //     //                         validation.resetForm();
    //     //                     } else {
    //     //                         toast("Failed to add Package", {
    //     //                             type: "error",
    //     //                         });
    //     //                     }
    //     //                 })
    //     //                 .catch((error) => {
    //     //                     console.error('Error creating Package:', error.message);
    //     //                     toast("Error creating category", {
    //     //                         type: "error",
    //     //                     });
    //     //                 });
    //     //         }
    //     //         catch (error) {
    //     //             console.error('Error creating Package:', error.message);
    //     //             toast("Error creating service", {
    //     //                 type: "error",
    //     //             });
    //     //         }

    //     //     }
    //     // }
    // });


    const onaddPackage = async () => {
        console.log('called else block')
        const selectedServiceId2 = selectedService;
        try {
            // const selectedServiceId2 = selectedService;
            const formData = new FormData();
            formData.append('service', selectedServiceId2);
            formData.append('packageName', values.packageName);
            formData.append('packageDays', values.packageDays);
            formData.append('packageAmount', values.packageAmount);
            formData.append('packageTask', values.packageTask);
            formData.append('status', values.status);

            const response = await axios.post(
                `${host}/package/create-package`,
                formData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: localStorage.getItem("token"),
                    },
                }
            );

            if (response.data.success) {
                GetAllPackages();
                toast("Successfully added Package!", {
                    type: "success",
                });
                toggle();
                validation.resetForm();
            } else {
                toast("Failed to add Package", {
                    type: "error",
                });
            }
        } catch (error) {
            console.error('Error creating Package:', error.message);
            toast("Error creating category", {
                type: "error",
            });
        }
    }

    const toggle = () => {
        if (modal) {
            setModal(false);
            SetState(null)
            setEditcategory(null)
        } else {
            setModal(true);
        }
    };

    const handleEditClick = arg => {
        // console.log(arg, 'arg')
        toggle();
        setIsEdit(true)
        // const data = arg;
        // setSelectedCategory(arg.category)
        // setSelectedService(arg.service)
        setSelectedCategory(arg.category._id)
        setSelectedService(arg.service._id)
        setFormState({
            editId: arg._id,
            // category: arg.category,
            // service: arg.service,
            packageName: arg.packageName,
            packageDays: arg.packageDays,
            packageTask: arg.packageTask,
            packageAmount: arg.packageAmount,
            status: arg.status,
        })
        // SetState({
        //     editId: arg._id,
        //     // Id: arg._id,:
        //     service: arg.service,
        //     packageName: arg.packageName,
        //     packageDays: arg.packageDays,
        //     packageTask: arg.packageTask,
        //     packageAmount: arg.packageAmount,
        //     status: arg.status,
        // });
        setIsEdit(true);
        toggle();
    };

    const handleCategoryClicks = () => {
        setCategoryData("");
        setIsEdit(false);
        toggle();
    };

    //delete category
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (data) => {
        console.log("onClickDelete", data)
        setEditcategory(data);
        setDeleteModal(true);
    };

    // Handle deleting a category
    const handleDeleteService = async () => {
        try {
            const response = await axios.delete(`${host}/package/delete-package/${delete_id}`, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            });
            if (response.data.success == true) {
                // dispatch(onGetCategoryList());
                toast("Successfully Deleted package!", {
                    type: "success",
                });
                // Fetch services after deleting
                GetAllPackages();
                setDeleteModal(false);
            } else {
                toast("Failed to Deleted package", {
                    type: "error",
                });
            }
        } catch (error) {
            console.error('Error Deleting package:', error.message);
            toast("Error Deleting package:", {
                type: "error",
            });
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: 'No',
                accessor: (row, index) => index + 1, // Auto-incremented number
                Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
                filterable: false,
            },
            {
                Header: 'Caregory Name',
                accessor: 'category',
                filterable: true,
                // Cell: ({ value }) => {
                //     //  `value` is the Service ID
                //     const filteredCategory = categories.find((category) => category._id === value);
                //     return filteredCategory ? filteredCategory.categoryName : 'loading..';
                //     // return filteredService ? filteredService.serviceName : service._id;
                // }
                Cell: ({ value }) => {
                    // console.log(value, 'value')
                    let Value = value?.categoryName;
                    return Value;
                }
            },
            {
                Header: 'Service Name',
                accessor: 'service',
                filterable: true,
                // Cell: ({ value }) => {
                //     //  `value` is the Service ID
                //     const filteredService = services.find((service) => service._id === value);
                //     return filteredService ? filteredService.serviceName : 'loading..';
                // }
                Cell: ({ value }) => {
                    // console.log(value, 'value')
                    let Value = value?.serviceName;
                    return Value;
                }
            },
            {
                Header: 'package Name',
                accessor: 'packageName',
                filterable: true,
                Cell: ({ value }) => <div style={{ textAlign: 'center2', width: '400px' }}>{value}</div>
            },
            {
                Header: 'package Days',
                accessor: 'packageDays',
                filterable: true,
            },
            {
                Header: 'package Amount',
                accessor: 'packageAmount',
                filterable: true,
            },
            {
                Header: 'packageTask',
                accessor: 'packageTask',
                filterable: true,
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterable: true,
            },
            {
                Header: 'createdAt',
                accessor: 'createdAt',
                Cell: ({ value }) => {
                    const localDate = new Date(value).toLocaleString();
                    return localDate;
                }
            },
            {
                Header: 'updatedAt',
                accessor: 'updatedAt',
                disableFilters: true,
                Cell: ({ value }) => {
                    const localDate = new Date(value).toLocaleString();
                    return localDate;
                }
            },
            {
                Header: 'Action',
                accessor: '_id',
                disableFilters: true,
                Cell: (cellProps) => {
                    const data = cellProps?.data?.find(elem => elem._id === cellProps.value)
                    // console.log(data, 'data')
                    // console.log(data._id)
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link to="#" className="btn btn-sm btn-soft-primary">
                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                ViewUncontrolledTooltip
                            </UncontrolledTooltip> */}
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    // onClick={() => {
                                    //     // const categoryData = cellProps.row.original;
                                    //     // handleEditClick(categoryData);
                                    // }}
                                    onClick={() => {
                                        setIsEdit(true)
                                        // toggle();
                                        setModal(true)
                                        handleEditClick(data);
                                        setEditId(data._id)
                                        // updateState({
                                        //     isEdit: true,
                                        //     // edit: data,
                                        //     service: data.service, packageName: data.packageName,
                                        //     packageDays: data.packageDays, packageTask: data.packageTask,
                                        //     packageAmount: data.packageAmount, status: data.status,
                                        // })
                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    // onClick={() => {
                                    //     // const categoryId = cellProps.row.original._id;
                                    //     onClickDelete(cellProps.value);
                                    // }}
                                    onClick={() => {
                                        setDeleteModal(true);
                                        updateState({
                                            // isDelete: true,                                       
                                            delete_id: cellProps.value,
                                        })
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );

    const [formState, setFormState] = useState({
        service: '',
        packageName: '',
        packageDays: '',
        packageAmount: '',
        packageTask: '',
        status: 'Active',
    });

    const [validationErrors, setValidationErrors] = useState({});

    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState(null);
    const [selectedService, setSelectedService] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    // const filteredServices = services.filter((service) => service.category === selectedCategory);
    const filteredServices = services.filter((service) => service.category._id === selectedCategory);
    console.log(filteredServices, 'filteredServices')


    const validateForm = () => {
        const errors = {};

        if (!selectedService) {
            errors.service = 'Please select a service';
        }

        if (!formState.packageName) {
            errors.packageName = 'Please enter package name';
        }

        if (!formState.packageDays) {
            errors.packageDays = 'Please enter package days';
        }

        if (!formState.packageAmount) {
            errors.packageAmount = 'Please enter package amount';
        }

        if (!formState.packageTask) {
            errors.packageTask = 'Please enter package task';
        }

        if (!formState.status) {
            errors.status = 'Please select a status';
        }

        setValidationErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate the form
        const isValid = validateForm();

        if (isValid) {
            try {
                const formData = new FormData();
                formData.append('category', selectedCategory);
                formData.append('service', selectedService);
                formData.append('packageName', formState.packageName);
                formData.append('packageDays', formState.packageDays);
                formData.append('packageAmount', formState.packageAmount);
                formData.append('packageTask', formState.packageTask);
                formData.append('status', formState.status);

                const url = isEdit
                    ? `${host}/package/update-package/${editId}`
                    : `${host}/package/create-package`;

                const response = await axios({
                    method: isEdit ? 'put' : 'post',
                    url,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: localStorage.getItem("token"),
                    },
                });

                if (response.data.success === true) {
                    // Reset form and perform other actions
                    console.log(isEdit ? 'Updated package!' : 'Added package!');
                    GetAllCategories();
                    GetAllPackages();
                    GetAllServices();
                    toast(`Successfully ${isEdit ? 'updated' : 'added'} Package!`, {
                        type: 'success',
                    });
                    toggle();
                    setFormState({
                        category: '',
                        service: '',
                        packageName: '',
                        packageDays: '',
                        packageAmount: '',
                        packageTask: '',
                        status: 'Active',
                    });
                    setIsEdit(false);
                    setEditId(null);
                } else {
                    toast(`Failed to ${isEdit ? 'update' : 'add'} Package`, {
                        type: 'error',
                    });
                }
            } catch (error) {
                console.error(`Error ${isEdit ? 'updating' : 'creating'} Package:`, error.message);
                toast(`Error ${isEdit ? 'updating' : 'creating'} Package`, {
                    type: 'error',
                });
            }
        } else {
            console.log('Form validation failed');
        }
    };

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteService}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Package Management" breadcrumbItem="Package List" />
                    <Row>
                        <Col lg="12">
                            {!isLoading && (
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Package Management</h5>
                                            <div className="flex-shrink-0">
                                                <Link to="#"
                                                    onClick={() => {
                                                        // console.log('add click')
                                                        setIsEdit(false)
                                                        setModal(true)
                                                    }
                                                        // updateState({ isEdit: true })
                                                    }
                                                    className="btn btn-primary me-1">Add Package
                                                </Link>
                                                <Link to="" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                                <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                    {/* <DropdownToggle type="menu" className="btn btn-success" id="dropdownMenuButton1">
                                                        <i className="mdi mdi-dots-vertical"></i></DropdownToggle> */}
                                                    {/* <DropdownMenu>
                                                        <li><DropdownItem href="#">Edit</DropdownItem></li>
                                                        <li><DropdownItem href="#">Delete</DropdownItem></li>
                                                        <li><DropdownItem href="#">Disable</DropdownItem></li>
                                                    </DropdownMenu> */}
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <TableContainer
                                            columns={columns}
                                            data={packages}
                                            // data={dummyData}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            // handleJobClicks={handleJobClicks}
                                            // handleJobClicks={handleCategoryClicks}
                                            // isJobListGlobalFilter={true}
                                            customPageSize={10}
                                        />
                                        {packages?.length == 0 && !isLoading && (
                                            <div className="p-5">
                                                <div className="text-center ">
                                                    <i
                                                        className="bx bx-search"
                                                        style={{ fontSize: "30px" }}
                                                    ></i>
                                                </div>
                                                <div className="text-center mt-3">No Packages Found</div>
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {/* {!!isEdit ? "Edit Category" : "Add Category"} */}
                            {isEdit == true ? "Edit Package " : "Add Package"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                // onSubmit={(e) => {
                                //     e.preventDefault();
                                //     console.log("object")
                                //     validation.handleSubmit();
                                //     return false;
                                // }}
                                onSubmit={handleSubmit}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label className="form-label"> Choose Caregory</Label>
                                            <Input
                                                name="category"
                                                type="select"
                                                value={selectedCategory}
                                                // onChange={validation.handleChange}
                                                // onBlur={handleBlur}
                                                onChange={(e) => {
                                                    // console.log(e.target.value, 'handleCategorySelect')
                                                    handleCategorySelect(e.target.value)
                                                }
                                                }
                                            >
                                                <option value="" disabled>Select a service</option>
                                                {categories?.map(category => (
                                                    <option key={category._id} value={category._id}>
                                                        {category?.categoryName}
                                                    </option>
                                                ))}
                                            </Input>
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label"> Choose Service</Label>
                                            <Input
                                                name="service"
                                                type="select"
                                                value={selectedService}
                                                // onBlur={handleBlur}
                                                // onChange={validation.handleChange}
                                                onChange={(e) => {
                                                    console.log(e.target.value)
                                                    handleServiceSelect(e.target.value)
                                                }
                                                }
                                            >
                                                <option value="" disabled>Select a service</option>
                                                {filteredServices?.map(services => (
                                                    <option key={services._id} value={services._id}>
                                                        {services?.serviceName}
                                                    </option>
                                                ))}
                                            </Input>
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Package Name</Label>
                                            <Input
                                                name="packageName"
                                                type="text"
                                                placeholder="Package Name"
                                                // onBlur={handleBlur}
                                                value={formState.packageName}
                                                onChange={(e) =>
                                                    setFormState({ ...formState, packageName: e.target.value })
                                                }
                                            // onBlur={() => handleBlur('packageName')}
                                            />
                                            {validationErrors.packageName && (
                                                <div className="text-danger">{validationErrors.packageName}</div>
                                                // <FormFeedback type="invalid">{validationErrors.packageName}</FormFeedback>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Package Days</Label>
                                            <Input
                                                name="packageDays"
                                                type="text"
                                                placeholder="package Days"
                                                value={formState.packageDays}
                                                onChange={(e) =>
                                                    setFormState({ ...formState, packageDays: e.target.value })
                                                }
                                            />
                                            {validationErrors.packageDays && (
                                                <div className="text-danger">{validationErrors.packageDays}</div>
                                                // <FormFeedback type="invalid">{validationErrors.packageDays}</FormFeedback>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Package Task</Label>
                                            <Input
                                                name="packageTask"
                                                type="text"
                                                placeholder="Package Task"
                                                value={formState.packageTask}
                                                onChange={(e) =>
                                                    setFormState({ ...formState, packageTask: e.target.value })
                                                }
                                            />
                                            {validationErrors.packageTask && (
                                                <div className="text-danger">{validationErrors.packageTask}</div>
                                                // <FormFeedback type="invalid">{validationErrors.packageTask}</FormFeedback>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Package Amount</Label>
                                            <Input
                                                name="packageAmount"
                                                type="Number"
                                                placeholder="Package Amount"
                                                value={formState.packageAmount}
                                                onChange={(e) =>
                                                    setFormState({ ...formState, packageAmount: e.target.value })
                                                }
                                            />
                                            {validationErrors.packageAmount && (
                                                <div className="text-danger">{validationErrors.packageAmount}</div>
                                                // <FormFeedback type="invalid">{validationErrors.packageAmount}</FormFeedback>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                value={formState.status}
                                                onChange={(e) =>
                                                    setFormState({ ...formState, status: e.target.value })
                                                }
                                            >
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </Input>
                                            {validationErrors.status && (
                                                <div className="text-danger">{validationErrors.status}</div>
                                            )}
                                        </div>

                                        {/* <div className="mb-3">
                                            <Label className="form-label">Package Name</Label>
                                            <Input
                                                name="packageName"
                                                type="textarea"
                                                placeholder="Package Name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.packageName || ""}
                                                invalid={
                                                    validation.touched.packageName && validation.errors.packageName ? true : false
                                                }
                                            />
                                            {validation.touched.packageName && validation.errors.packageName ? (
                                                <FormFeedback type="invalid">{validation.errors.packageName}</FormFeedback>
                                            ) : null}
                                        </div> */}
                                        {/* <div className="mb-3">
                                            <Label className="form-label">Package Days</Label>
                                            <Input
                                                name="packageDays"
                                                type="text"
                                                placeholder="packageDays"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.packageDays || ""}
                                                invalid={
                                                    validation.touched.packageDays && validation.errors.packageDays ? true : false
                                                }
                                            />
                                            {validation.touched.packageDays && validation.errors.packageDays ? (
                                                <FormFeedback type="invalid">{validation.errors.packageDays}</FormFeedback>
                                            ) : null}
                                        </div> */}

                                        {/* <div className="mb-3">
                                            <Label className="form-label"> Package Task</Label>
                                            <Input
                                                name="packageTask"
                                                type="text"
                                                placeholder="Enter packageTask"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.packageTask || ""}
                                                invalid={
                                                    validation.touched.packageTask && validation.errors.packageTask ? true : false
                                                }
                                            />
                                            {validation.touched.packageTask && validation.errors.packageTask ? (
                                                <FormFeedback type="invalid">{validation.errors.packageTask}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label"> Package Amount</Label>
                                            <Input
                                                name="packageAmount"
                                                type="text"
                                                placeholder="Enter packageAmount"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.packageAmount || ""}
                                                invalid={
                                                    validation.touched.packageAmount && validation.errors.packageAmount ? true : false
                                                }
                                            />
                                            {validation.touched.packageAmount && validation.errors.packageAmount ? (
                                                <FormFeedback type="invalid">{validation.errors.packageAmount}</FormFeedback>
                                            ) : null}
                                        </div> */}

                                        {/* <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.status || ""
                                                }
                                                invalid={
                                                    validation.touched.status && validation.errors.status ? true : false
                                                }
                                            >
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </Input>
                                            {validation.touched.status && validation.errors.status ? (
                                                <FormFeedback status="invalid">{validation.errors.status}</FormFeedback>
                                            ) : null}
                                        </div> */}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            // onClick={onaddPackage()}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </React.Fragment >
    );
}


export default PackageManage;