import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { host, API_URL } from 'helpers/api_helper';

const dummyImageUrl = "https://socialbooster-prod.s3.eu-west-2.amazonaws.com/img/insta.svg"
// const API_URL = "http://localhost:8001";
// const API_URL = "http://www.snapbooster.us:8001";

// process.env.REACT_APP_HOST ||z
const ImageCell2 = ({ cell: { value } }) =>
    console.log(value.icon, 'icon')

        (
            <>
                {
                    value?.icon ? (
                        <img
                            src={`${API_URL}/${value?.icon}`}
                            alt="Icon"
                            style={{ width: '30px', height: '30px' }}
                        />
                    ) : (
                        <span>No Icon</span>
                    )}
            </>
        );
const ImageCell = ({ cell: { value } }) => {
    // console.log(value, 'category icon');
    return (
        <>
            {value ? (
                <img
                    src={`${API_URL}/${value}`}
                    alt="Icon"
                    style={{ width: '30px', height: '30px' }}
                />
            ) : (
                <span>No Icon</span>
                // <img
                //     src="https://socialbooster-prod.s3.eu-west-2.amazonaws.com/img/insta.svg"
                //     alt="Icon" style={{ width: '30px', height: '30px' }}
                // />
            )}
        </>
    );
};
// const ImageCell2 = ({ cell: { value } }) => (
//     <>
//         <img
//             // src="https://socialbooster-prod.s3.eu-west-2.amazonaws.com/img/insta.svg"
//             src={value.icon ? `${API_URL}/${value?.icon}` : ''}
//             alt="Icon" style={{ width: '30px', height: '30px' }}
//         />
//     </>
// );


const Type = (cell) => {
    switch (cell.value) {
        case "Full Time":
            return <Badge className="badge-soft-success">Full Time</Badge>
        case "Part Time":
            return <Badge className="badge-soft-danger">Part Time</Badge>
        case "Freelance":
            return <Badge className="badge-soft-info">Freelance</Badge>
        case "Internship":
            return <Badge className="badge-soft-warning">Internship</Badge>
    };
};


export { ImageCell };