import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
// import { jobs } from "../../../common/data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getCategorylist as onGetCategoryList,
} from "store/actions";

import { ImageCell } from "./categoryListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import { deleteCategory } from "../../../helpers/smm_helper";
import AdminContext from '../../../context/adminContext';
import axios from 'axios';
// import { host, API_URL } from 'helpers/api_helper';

// function CategoryList() {
const CategoryList = () => {
    const context = useContext(AdminContext);
    const { AddCategory } = context;
    // port
    const host = process.env.REACT_APP_HOST
    // const host = "http://localhost:8001/api/v2"
    //meta title
    document.title = "Category Management|";

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    // const [editID, setEditId] = useState('');
    const [categoryData, setCategoryData] = useState([])
    const [editCategory, setEditcategory] = useState(null);

    const [State, SetState] = useState(null);

    // image state
    const [categoryIcon, setCategoryicon] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImagechange = (e) => {
        setCategoryicon(e.target.files[0]);
        const file = e.target.files[0];

        if (file) {
            setSelectedImage(URL.createObjectURL(file));
        // Additional logic if needed
        }
    }
    const handleRemoveImage = () => {
        setSelectedImage(null);
    };

    const [state, setState] = useState({
        editId: null,
        isLoading: false,
        delete_id: null,
        edit: null,
    })
    const {
        editId,
        isLoading,
        delete_id,
        // isEdit,
        edit,
    } = state


    const updateState = data => setState(state => ({ ...state, ...data }))

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            // Id: (State && State.Id) || '',
            categoryName: (State && State.categoryName) || '',
            // categoryIcon: (State && State.categoryIcon) || null,
            description: (State && State.description) || '',
        },
        validationSchema: Yup.object({
            categoryName: Yup.string().required("Please Enter Category Name"),
            // categoryIcon: Yup.string().required("Please Select Icon"),
            description: Yup.string().required("Please Enter Description"),
        }),
        onSubmit: async (values) => {
            // if when click on edit button setEdit will be true then edit api will call  
            if (isEdit == true) {
                const editId = State.editId

                try {
                    const formData = new FormData();
                    formData.append('categoryName', values.categoryName);
                    formData.append('description', values.description);
                    formData.append('icon', categoryIcon);
                    const response = await axios.put(`${host}/category/update-category/${editId}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: localStorage.getItem("token"),
                        },
                    });

                    if (response.data.success) {
                        dispatch(onGetCategoryList());
                        toast("Successfully updated!", {
                            type: "success",
                        });
                        toggle();
                        validation.resetForm();
                        setCategoryicon(null)
                    } else {
                        toast("Failed to update category", {
                            type: "error",
                        });
                    }
                } catch (error) {
                    console.error('Error updating category:', error.message);
                    toast("Error updating category", error.message, {
                        type: "error",
                    });
                }
            } else {
                try {
                    const formData = new FormData();
                    formData.append('categoryName', values.categoryName);
                    formData.append('description', values.description);
                    formData.append('icon', categoryIcon);
                    axios.post(`${host}/category/create-category`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: localStorage.getItem("token"),
                        },
                    })
                        .then((response) => {
                            // console.log(response.data);
                            if (response.data.success == true) {
                                dispatch(onGetCategoryList());
                                toast("Successfully added!", {
                                    type: "success",
                                });
                                toggle();
                                validation.resetForm();
                                setCategoryicon(null)

                            } else {
                                toast("Failed to add category", {
                                    type: "error",
                                });
                            }
                        })
                        .catch((error) => {
                            console.error('Error creating category:', error.message);
                            toast("Error creating category or Duplicate Category not allowed", {
                                type: "error",
                            });
                        });
                } catch (error) {
                    console.error('Error creating category:', error);
                    toast("Error creating category", error, {
                        type: "error",
                    });
                }
            }
        }
    });

    const dispatch = useDispatch();

    const { categoryList } = useSelector(state => ({
        categoryList: state.CategoryReducer.categoryList,
    }));

    useEffect(() => {
        // Fetch category list only if it's not already loaded
        if (!categoryList || !categoryList.categories || !categoryList.categories.length) {
            dispatch(onGetCategoryList());
        }
    }, [categoryList, dispatch]);

    useEffect(() => {
        // Update local state when categoryList.categories changes
        if (categoryList && categoryList.categories) {
            setCategoryData(categoryList.categories);
        }
    }, [categoryList]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            SetState(null)
            setEditcategory(null)
        } else {
            setModal(true);
        }
    };

    // const [xid, setxid] = useState('')
    const handleEditClick = arg => {
        // console.log(arg._id, 'arg id')
        toggle();
        setIsEdit(true)
        // setxid(arg._id)
        const data = arg;
        SetState({
            editId: arg._id,
            // Id: arg._id,
            categoryName: arg.categoryName,
            description: arg.description,
            categoryIcon: arg.categoryIcon,
        });
        setIsEdit(true);
        toggle();
    };

    const handleCategoryClicks = () => {
        setCategoryData("");
        setIsEdit(false);
        toggle();
    };

    //delete category
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (data) => {
        console.log("onClickDelete", data)
        setEditcategory(data);
        setDeleteModal(true);
    };

    // const handleDeletejob = () => {
    //     if (job && job.id) {
    //         dispatch(onDeleteJobList(job.id));
    //         setDeleteModal(false);
    //     }
    // };


    // Handle deleting a category
    const handleDeleteCategory = async () => {
        try {
            // if (editCategory && editCategory._id) {
            //     console.log(editCategory._id)
            //     await deleteCategory(editCategory._id);
            //     // Fetch categories after deleting
            //     dispatch(onGetCategoryList());
            //     setDeleteModal(false);
            // }
            console.log(delete_id, "delete_id")
            await deleteCategory(delete_id);
            // Fetch categories after deleting
            dispatch(onGetCategoryList());
            setDeleteModal(false);
        } catch (error) {
            console.error("Error deleting category:", error);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: 'No',
                accessor: (row, index) => index + 1, // Auto-incremented number
                Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
                filterable: false,
            },
            // {
            //     Header: 'No',
            //     accessor: '_id',
            //     // filterable: true,
            // },
            {
                Header: 'Category Name',
                accessor: 'categoryName',
                filterable: true,
            },
            {
                Header: 'Icon',
                accessor: 'icon',
                filterable: true,
                // Cell: (cellProps) => {
                //     const data = cellProps.data.find(elem => elem.icon === cellProps.value)
                //     console.log(data, 'icon')
                //     return (
                //         <ImageCell {...cellProps} />
                //     )
                // }
                Cell: ({ cell: { value } }) => {
                    // console.log(value, 'icon');
                    return <ImageCell cell={{ value }} />;
                },
            },
            // {
            //     Header: 'Icon',
            //     accessor: 'icon',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ImageCell {...cellProps} />;
            //     }
            // },
            {
                Header: 'Deccription',
                accessor: 'description',
                filterable: true,
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                Cell: ({ value }) => {
                    const localDate = new Date(value).toLocaleString();
                    return localDate;
                }
            },
            {
                Header: 'Updated At',
                accessor: 'updatedAt',
                disableFilters: true,
                Cell: ({ value }) => {
                    const localDate = new Date(value).toLocaleString();
                    return localDate;
                }
            },
            {
                Header: 'Action',
                accessor: '_id',
                disableFilters: true,
                Cell: (cellProps) => {
                    const data = cellProps?.data?.find(elem => elem._id === cellProps.value)
                    // console.log(data)
                    // console.log(data._id)
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link to="#" className="btn btn-sm btn-soft-primary">
                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                ViewUncontrolledTooltip
                            </UncontrolledTooltip> */}
                            <li>
                                <Link
                                    to=""
                                    className="btn btn-sm btn-soft-info"
                                    // onClick={() => {
                                    //     // const categoryData = cellProps.row.original;
                                    //     // handleEditClick(categoryData);
                                    // }}
                                    onClick={() => {
                                        setIsEdit(true)
                                        setModal(true)
                                        handleEditClick(data);
                                        // setEditId(data._id)
                                        // updateState({
                                        //     editId: data._id,
                                        //     // isEdit: true,
                                        //     // edit: data,
                                        // })
                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to=""
                                    className="btn btn-sm btn-soft-danger"
                                    // onClick={() => {
                                    //     // const categoryId = cellProps.row.original._id;
                                    //     onClickDelete(cellProps.value);
                                    // }}
                                    onClick={() => {
                                        setDeleteModal(true);
                                        updateState({
                                            // isDelete: true,                                       
                                            delete_id: cellProps.value,
                                        })
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );

    const Reload = () => {
        window.location.reload()
    }
    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                // onDeleteClick={handleDeletejob}
                onDeleteClick={handleDeleteCategory}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Category Management" breadcrumbItem="Category List" />
                    <Row>
                        <Col lg="12">
                            {!isLoading && (
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Category Management</h5>
                                            <div className="flex-shrink-0">
                                                <Link to="" className="btn btn-light me-1"><i className="mdi mdi-refresh"
                                                    onClick={Reload}></i>
                                                </Link>
                                                <Link to=""
                                                    onClick={() => {
                                                        // setIsEdit(false)
                                                        setIsEdit(false)
                                                        setModal(true)
                                                    }
                                                        // updateState({ isEdit: true })
                                                    }
                                                    className="btn btn-primary me-1">Add Category
                                                </Link>

                                                {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                    <DropdownToggle type="menu" className="btn btn-success" id="dropdownMenuButton1">
                                                        <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                                                    <DropdownMenu>
                                                        <li><DropdownItem href="#">Edit</DropdownItem></li>
                                                        <li><DropdownItem href="#">Delete</DropdownItem></li>
                                                        <li><DropdownItem href="#">Disable</DropdownItem></li>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown> */}
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <TableContainer
                                            columns={columns}
                                            data={categoryData}
                                            // data={dummyData}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            // handleJobClicks={handleJobClicks}
                                            handleJobClicks={handleCategoryClicks}
                                            // isJobListGlobalFilter={true}
                                            customPageSize={10}
                                        />
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {/* {!!isEdit ? "Edit Category" : "Add Category"} */}
                            {isEdit == true ? "Edit Category" : "Add Category"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label className="form-label"> Category Name</Label>
                                            <Input
                                                name="categoryName"
                                                type="text"
                                                placeholder="Enter CategoryName"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.categoryName || ""}
                                                invalid={
                                                    validation.touched.categoryName && validation.errors.categoryName ? true : false
                                                }
                                            />
                                            {validation.touched.categoryName && validation.errors.categoryName ? (
                                                <FormFeedback type="invalid">{validation.errors.categoryName}</FormFeedback>
                                            ) : null}
                                        </div>
                                        {/* <div className=" text-lg-center text-sm-center mx-2 mt-2 mb-2">

                                            <>
                                                <Col lg={12}>
                                                    {" "}
                                                    <i
                                                        className="dripicons-cross font-size-24 float-end "
                                                        onClick={() => {
                                                            setCategoryicon('')                                                            // setImgdata("")
                                                        }}
                                                    ></i>{" "}
                                                </Col>
                                                <img
                                                    // src="${host}/Form_Images/1690959551221.png"
                                                    // src={logo ? preview : `${host}/${imgdata}`}
                                                    src={
                                                        categoryList ? `${host}/${categoryList.icon}` : preview
                                                    }
                                                    // src={`${host}/${imgdata}`}
                                                    alt='icon'
                                                    style={{ width: "130px", height: "70px" }}
                                                />
                                            </>

                                        </div> */}

                                        <div className="mb-3">
                                            <Label className="form-label">Select Icon</Label>
                                            <Input
                                                name="categoryIcon"
                                                type="file"
                                                placeholder="Select Icon"
                                                // validate={{
                                                //     required: { value: true },
                                                // }}
                                                // onChange={validation.handleChange}
                                                onChange={(e) => { handleImagechange(e); }}
                                            // onBlur={validation.handleBlur}
                                            // value={validation.values.categoryIcon || ""}
                                            // invalid={
                                            //     validation.touched.categoryIcon && validation.errors.categoryIcon ? true : false
                                            // }
                                            />
                                            {validation.touched.categoryIcon && validation.errors.categoryIcon ? (
                                                <FormFeedback type="invalid">{validation.errors.categoryIcon}</FormFeedback>
                                            ) : null}
                                            {selectedImage && (
                                                <div className="mt-2 d-flex align-items-center">
                                                    <img
                                                        src={selectedImage}
                                                        alt="Selected Icon"
                                                        style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline btn-sm"
                                                        onClick={handleRemoveImage}
                                                        style={{ fontSize: '25px' }}
                                                    >
                                                        {/* <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                                                            <i className="mdi mdi-trash-can-outline"></i>
                                                        </div> */}
                                                        <button type="button"
                                                            className="btn-close"></button>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Category Description</Label>
                                            <Input
                                                name="description"
                                                type="text"
                                                placeholder="Enter Description"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.description || ""}
                                                invalid={
                                                    validation.touched.description && validation.errors.description ? true : false
                                                }
                                            />
                                            {validation.touched.description && validation.errors.description ? (
                                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                            ) : null}
                                        </div>
                                        {/* <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.status || ""
                                                }
                                                invalid={
                                                    validation.touched.status && validation.errors.status ? true : false
                                                }
                                            >
                                                <option>Active</option>
                                                <option>New</option>
                                                <option>Close</option>
                                            </Input>
                                            {validation.touched.status && validation.errors.status ? (
                                                <FormFeedback status="invalid">{validation.errors.status}</FormFeedback>
                                            ) : null}
                                        </div> */}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    );
}


export default CategoryList;