import React, { useEffect, useMemo, useState, useContext } from "react";
import "./invoice.css"
import {
  Button,
  Card, CardBody, Col,
  Container, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback,
  Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, UncontrolledDropdown
} from "reactstrap";

import Select from 'react-select';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AdminContext from 'context/adminContext';

//i18n
import { withTranslation } from "react-i18next";
import { Form, Link } from "react-router-dom";
import TableContainer from "components/Common/TableContainer";
import * as Yup from "yup";
import { useFormik } from "formik";
import AddModal from "./AddModal";

const SuperAdmin = props => {
  //meta title
  document.title = "Users Management | Snapbooster";
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const context = useContext(AdminContext);
  const { GetAllUser, allUser } = context;
  // console.log(allUser, "all")

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,

    initialValues: {

    },

    onSubmit: (values) => {

    },
  });

  useEffect(() => {
    GetAllUser();
  }, [])

  const columns = useMemo(
    () => [

      {
        Header: 'UserName',
        filterable: true,
        accessor: 'username',
      },
      {
        Header: 'UserId',
        filterable: true,
        accessor: '_id'
      },
      {
        Header: 'Email',
        filterable: true,
        accessor: 'email'
      },
      {
        Header: 'Role',
        filterable: true,
        accessor: 'isAdmin',
        Cell: ({ value }) => {
          return value === true ? 'Admin' : 'User';
        }
      },
      // {
      //   Header: 'Verification',
      //   accessor: 'verification',
      //   filterable: true,
      //   Cell: ({ value }) => {
      //     return value === true ? 'Verified' : 'Not Verified';
      //   }
      // },
      {
        Header: 'Joining date',
        accessor: 'createdAt',
        filterable: true,
        Cell: ({ value }) => {
          const localDate = new Date(value).toLocaleString();
          return localDate;
        }
      },
    ],
    []
  );



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("All Users")}
            breadcrumbItem={props.t("All Users")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    {/* <h5 className="mb-0 card-title flex-grow-1">Add Admin</h5> */}
                    <div className="flex-shrink-0">
                      {/* <Link to="#!" onClick={() => setModal(true)} className="btn btn-primary me-1">Add New </Link> */}
                      {/* <div className='btn me-1'> <AddModal /> </div>
                      <Link to="#!" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link> */}
                      {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
                        <DropdownToggle type="menu" className="btn btn-success" id="dropdownMenuButton1">
                          <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                        <DropdownMenu>
                          <li><DropdownItem href="#">Action</DropdownItem></li>
                          <li><DropdownItem href="#">Another action</DropdownItem></li>
                          <li><DropdownItem href="#">Something else here</DropdownItem></li>
                        </DropdownMenu>
                      </UncontrolledDropdown> */}
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={allUser}
                    isGlobalFilter={true}
                    // isJobListGlobalFilter={false}
                    isAddOptions={false}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* add modal */}
          {/* <AddModal /> */}

        </Container>

      </div>
    </React.Fragment>
  );
};


export default withTranslation()(SuperAdmin);