import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

// Get categories
export const getCategories = () => get(url.GET_CATEGORIES);
// // add 
// export const addCategory = () => post(url.CREATE_CATEGORY);

// export const editCategory = () => put(url.UPDATE_CATEGORY);

// export const deleteCategory = () => del(url.DELETE_CATEGORY);

// // Add category
// export const addCategory = (categoryData) => post(url.CREATE_CATEGORY, categoryData);

// // Edit category
// export const editCategory = (categoryId, categoryData) => put(`${url.UPDATE_CATEGORY}/${categoryId}`, categoryData);

// Delete category
export const deleteCategory = (categoryId) => del(`${url.DELETE_CATEGORY}/${categoryId}`,
    {
        headers: {
            Authorization: localStorage.getItem("token"),
        },
    });

// Add category with multipart form data
export const addCategory = (categoryData) => {
    const formData = new FormData();

    // Append each field to the form data
    Object.keys(categoryData).forEach((key) => {
        formData.append(key, categoryData[key]);
    });

    // Make the POST request with multipart form data
    return post(url.CREATE_CATEGORY, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

// Edit category with multipart form data
export const editCategory = (categoryId, categoryData) => {
    const formData = new FormData();

    // Append each field to the form data
    Object.keys(categoryData).forEach((key) => {
        formData.append(key, categoryData[key]);
    });

    // Make the PUT request with multipart form data
    return put(`${url.UPDATE_CATEGORY}/${categoryId}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};