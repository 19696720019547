import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Authmiddleware = (props) => {

  // const isAdmin = localStorage.getItem('isAdmin')
  // console.log(isAdmin, 'isAdmin')
  // const token = localStorage.getItem("token");
  // console.log(token)
  // const Role = localStorage.getItem('role')
  // console.log(Role)

  // if (localStorage.getItem('role') == "admin") {
  //   // if (!localStorage.getItem("token")) {
  //   toast("Please Login First or Unauthorized ", {
  //     type: "error",
  //   });

  if (localStorage.getItem('role') !== "admin" && localStorage.getItem('isAdmin') !== true) {
    toast("Unauthorized Access", {
      type: "error",
    });
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  <Navigate to={{ pathname: "/login", state: { from: props.location } }} />

  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
