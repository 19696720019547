import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  // to show superadmin menu only for admin
  const isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  // console.log(isAdmin)
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard" className="has-arrow">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/dashboard">{props.t("Home")}</Link>
                </li>
              </ul>
            </li>
            <li>
              {/* <li>
                <Link to="/dashboard">{props.t("Default")}</Link>
              </li> */}
              {/* {isAdmin === true ? (
                <Link to="/superadmin" className="has-arro ">
                  <i className="bx bx bxl-blogger"></i>
                  <span>{props.t("Admin")}</span>
                </Link>
              )
                : ""
              } */}
              <Link to="/superadmin" className="has-arro ">
                <i className="bx bx bxl-blogger"></i>
                <span>{props.t("All Users")}</span>
              </Link>
              <Link to="/managecategory" className="has-arro">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Manage Category")}</span>
              </Link>
              <Link to="/manageservices" className="has-arro">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Manage Services")}</span>
              </Link>
              <Link to="/managepackage" className="has-arro">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Manage Package")}</span>
              </Link>
              <Link to="/manageorders" className="has-arro">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Manage Orders")}</span>
              </Link>
              {/* <Link to="/manageUsers" className="has-arro">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Manage User")}</span>
              </Link> */}
              {/* <li>
                <Link to="/jobs">
                <span className="badge rounded-pill text-bg-success float-end" key="t-new">New</span>
                  {props.t("Jobs")}
                </Link>
              </li> */}
            </li>
            <li>
              <Link
                className="has-arrow">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Website CMS")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/manage-web-cms">{props.t("Home Page CMS")}</Link>
                </li>
                {/* <li>
                    <Link to="/manage-web-cms2">{props.t("Home Page CMS 2")}</Link>
                  </li> */}
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
