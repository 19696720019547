import React, { useState, useContext } from "react";
import {
  Container, Card, CardBody, CardTitle, Form, Row, Col, Label, Input, Button
} from "reactstrap";
import AdminContext from 'context/adminContext';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
const Admin = props => {

  //meta title
  document.title = "Admin | Skote - React Admin ";

  const context = useContext(AdminContext);
  const { user } = context;


  // https://bro.mirotalk.com/viewer?id=f156162e-71e9-47c5-b069-c1cc1b44e3ad&name=User-82885
  // http://localhost:3016/broadcast?id=123&name=Broadcaster

  // const [username, setuserName] = useState('')
  // const [userId, setuserId] = useState('')

  // const [copy, setcopy] = useState("copy")

  // var val = Math.floor(1000 + Math.random() * 9000);
  // const [Random, setval] = useState(val)


  const CopyUrl = () => {
    let url = `http://localhost:3016/broadcast?id=${user._id}&name=${user.username}`
    navigator.clipboard.writeText(url)
  }

  const CreateChat = async () => {
    console.log("clicked")
    window.location.assign(`http://localhost:3016/broadcast?id=${user._id}&name=${user.username}`)
    // window.location.replace(`http://localhost:3016`)
  }

  // const createChat = async () => {
  //   // e.preventDefault()

  //   if (arr.length > 0 && arr1.length > 0) {
  //     console.log(arr1)
  //     console.log(arr)
  //     let res = await axios.post(`${url}/create/chat`, { arr1, arr, u_type, user_name, Random, user_id }, {
  //       headers: {

  //         'authorization': JSON.parse(localStorage.getItem("user"))
  //       },
  //     })
  //     if (res.status === 200) {
  //       // console.log(res.data)
  //       toast("success created chat ", {
  //         type: 'success'
  //       });

  //       location.replace(`https://voicechat.online:3000/join/${Random}${user_id}${arr.length}?client=${client_name}&type=${type}`);

  //     }
  //     else {
  //       toast("something Went wrong ", {
  //         type: 'error'
  //       });
  //     }
  //   } else {
  //     toast("something Went wrong ! try again ", {
  //       type: 'warning'
  //     });
  //   }
  // }

  return (
    <React.Fragment>
      <div className="page-content" >
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("admin")}
            breadcrumbItem={props.t("admin")}
          />
          <div
          // style={{ margin: '30px' }}
          // className="flex-wrap container"
          >
            <Row >
              <Col
              // xl={6} lg={6} sm={12}
              >
                <Card
                  // xl={6} lg={6} sm={12}
                  // className="my-2"
                  style={{
                    // border: '2px solid blue',
                    display: 'flex',
                    flex: 'flex-wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '28vh',
                    // margin: '100px',
                    // width: '100%',
                  }} >
                  <CardBody>
                    <CardTitle className="mb-4  text-primary text-center ">My Personal Metting Id</CardTitle>
                    <Button
                      color="dark"
                      className="w-md mb-2"
                    >
                      <h5 className="font-size-18">{user._id}</h5>
                      <p className="text-muted">Metting Id</p>
                    </Button>
                    {/* <Form>
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          UserName
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder="Enter Your"
                            value={username}
                            onChange={(e) => {
                              // handleInputs(e);
                              setuserName(e.target.value)
                              console.log(username)
                            }} />
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-userId-Input"
                          className="col-sm-3 col-form-label"
                        >
                          User Id :
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="text"
                            className="form-control"
                            id="horizontal-userId-Input"
                            placeholder="Enter Your User ID"
                            value={userId}
                            onChange={(e) => {
                              setuserId(e.target.value)
                            }}
                          />
                        </Col>
                      </Row>


                      <Row className="justify-content-end">
                        <Col sm={9}>
                         


                        </Col>
                      </Row>
                    </Form> */}
                    <div>
                      <Button
                        type="submit"
                        color="dark"
                        className="w-md"
                        onClick={() => {
                          CreateChat()
                        }}
                      >
                        Start

                        {/* //http://localhost:3016/broadcast?id=${val}&name=${username} */}

                      </Button>

                      <Button
                        type="submit"
                        color="dark"
                        className="w-md mx-2"
                        onClick={() => {
                          CopyUrl()
                        }}
                      >
                        Copy Url
                        {/* //http://localhost:3016/broadcast?id=${val}&name=${username} */}

                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Card>
                <iframe
                  allow="camera; microphone; display-capture; fullscreen; clipboard-read; clipboard-write; autoplay"
                  // src="https://sfu.mirotalk.com/join/your_room_name"
                  src={`http://localhost:3016/broadcast?id=${user._id}&name=${user.username}`}
                  // style="height: 100vh; width: 100vw; border: 0px;"
                  style={{ height: '100vh', width: '100vw', border: '0px' }}
                ></iframe>
              </Card>

              {/* <iframe
                allow="camera; microphone; display-capture; fullscreen; clipboard-read; clipboard-write; autoplay"
                src="https://sfu.mirotalk.com/newroom"
                style={{ height: '100vh', width: '100vw', border: '0px' }}
              ></iframe> */}
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withTranslation()(Admin);
