import React, { useContext } from "react"
import { Navigate } from "react-router-dom"

// landing page
// import LandingPage from "../pages/LandingPage/Landing"
import LandingPage from "../pages/Crypto/CryptoIcoLanding"

// import Crypto from "../pages/Crypto/CryptoIcoLanding/"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Client from "../pages/Dashboard/Client"
import Admin from "../pages/Dashboard/Admin"
import SuperAdmin from "../pages/Dashboard/SuperAdmin"

//SMM pages 
import UserManage from "pages/SmmPanel/UserManagement";
import CategoryManage from "pages/SmmPanel/CategoryManage";
import ServiceManage from "../pages/SmmPanel/ServiceManage";
import PackageManage from "pages/SmmPanel/PackageManagement";
import ManageOrders from "pages/SmmPanel/OrderManagement";
import HomePageManage from "pages/Cms-management/Home-page"


// import JobList from '../pages/JobList'
// import Catogery from "../pages/JobPages/JobCategories"
// import AddServices from "pages/SmmPanel/AddServices"
// import AddCategory from "pages/SmmPanel/AddCategory"

// const isAdmin = JSON.parse(localStorage.getItem('isAdmin'))

// const AdminMenu = [
//   { path: "/dashboard", component: <Dashboard /> },
//   { path: "/host", component: <Admin /> },
//   { path: "/superadmin", component: <SuperAdmin /> },
//   // //profile
//   { path: "/profile", component: <UserProfile /> },
// ]
// const UserMenu = [
//   { path: "/dashboard", component: <Dashboard /> },
//   { path: "/host", component: <Admin /> },
//   //profile
//   { path: "/profile", component: <UserProfile /> },
//   { path: "/social", component: <Catogery /> },
// ]
// {isAdmin === true ? (
//   <>
//   </>
// ) : null
// }
// const menuToRender = isAdmin === true ? AdminMenu : UserMenu;


const authProtectedRoutes = [
  // Dashboard
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/superadmin", component: <SuperAdmin /> },

  // SMM Routes
  // { path: "/manageUsers", component: <UserManage /> },
  { path: "/managecategory", component: <CategoryManage /> },
  { path: "/manageservices", component: <ServiceManage /> },
  { path: "/managepackage", component: <PackageManage /> },
  { path: "/manageorders", component: <ManageOrders /> },
  { path: "/manage-web-cms", component: <HomePageManage /> },


  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name

  // {
  //   path: "/",
  //   exact: true,
  //   component: < Navigate to="/dashboard" />,
  // },

  // {
  //   path: "*",
  //   exact: true,
  //   component: (
  //     <Navigate
  //       to={localStorage.getItem("T") == "O" ? "/home/dashboard" : "/dashboard"}
  //     />
  //   ),
  // },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  //  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  { path: "/Recoverpw", component: <Recoverpw /> },
  // { path: "/dashboard", component: <Dashboard /> },

  // landing page
  { path: "/", component: <Login /> },
]

export { publicRoutes, authProtectedRoutes }

